<template>
  <div>
    <MobileMenu
      v-if="menu"
      @close="menu=false"
    />
    <header class="header">
      <div class="header__content">
        <div>
          <a class="logo" href="/"></a>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import './header.scss';
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  data() {
    return {
      menu: false
    };
  },
  computed: {
    ...mapGetters({
      user: 'application/user'
    }),
    isAuth() {
      return !!this.user.contactData.id
    },
    fullName() {
      return [this.user.contactData?.firstname, this.user.contactData?.lastname].join(' ')
    }
  },
  methods: {
    openMenu() {
      this.menu = true;
    },
  },
  components: {
    MobileMenu: () => import('./MobileMenu'),
  }
};
</script>
