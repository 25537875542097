<template>
  <!-- @TODO избавиться от нужны пихать header и footer Сделаем отдельней сегмент роутов с шапкой и футером Компонент в шапке задается через ПРОП -->
  <form @submit.prevent="submit" novalidate class="unsubscribe-page">
    <Header class="unsubscribe-page__header"> </Header>
    <div class="unsubscribe-page__content">
      <Title class="unsubscribe-page__title"> Отказаться от подписки </Title>
      <p class="unsubscribe-page__desc">
        Укажите номер телефона, который Вы использовали при регистрации.
      </p>
      <div class="unsubscribe-page__form">
        <Phone
          v-model="form.phone"
          :error="formErrors.phone"
          title="Номер телефона"
        />
      </div>
      <div class="unsubscribe-page__actions">
        <Button type="submit"> Продолжить </Button>
      </div>
    </div>
    <Footer />
  </form>
</template>

<script>
import Header from "@index/components/main/header/Header";
import Phone from "@index/components/common/field/Phone";
import Button from "@index/components/gui/button/Button";
import Title from "@index/components/gui/title/Title";
import "./unsubscribe-page.scss";

import validation from "@index/mixins/validation";
import Validation from "@index/ext/validation/Validation";

import sendUnsubscribe from "@index/api/sendUnsubscribe";

export default {
  name: "UnsubscribePage",
  data() {
    return {
      form: {
        phone: "",
      },
      formRules: {
        phone: [Validation.REQUIRED, Validation.PHONE],
      },
    };
  },
  mixins: [validation],
  methods: {
    async submit() {
      this.validate();

      if (!this.isValid) return;

      const info = await sendUnsubscribe(this.form);

      const routeName =
        info.status === "phoneNotFound"
          ? "UnsubscribeInfo"
          : "UnsubscribeMessage";

      this.$router.push({
        name: routeName,
        params: {
          info,
          data: this.form,
        },
      });
    },
  },
  components: {
    Header,
    Phone,
    Button,
    Title,
    Footer: () => import("@index/components/main/footer/Footer"),
  },
};
</script>
